import ApiService from "./ApiService";

export async function apiPostFeedbacks (guid, token, data) {
    return ApiService.fetchData({
        url: `/issues/${guid}/feedbacks`,
        headers: {
            'Authorization': `Bearer ${token}` 
        },
        method: 'post',
        data
        //params
    })
}
