import { createSlice } from '@reduxjs/toolkit'
import { transformDataStatus } from 'utils/helpers'



export const landingData = createSlice({
    name: 'landing',
    initialState: {
      content: null,
      feedbacks: null, 
      form:null, 
      id: null, 
      landing: null, 
      language_code:null, 
      media_server: null, 
      meta: null, 
      name: null, 
      permissions: null,
      recomendations:null, 
      statuses:null, 
      tags:null, 
      timezone_code: null, 
      tracking: null, 
      tos: null, 
      loading: true
    
    },
    
    reducers: {
      setGlobalLandigData: (state, action) => {
        state.content = action.payload.content
        state.feedbacks = action.payload.feedbacks
        state.form = action.payload.form
        state.id = action.payload.id
        state.landing = action.payload.landing
        state.language_code = action.payload.language_code
        state.media_server = action.payload.media_server
        state.meta = action.payload.meta
        state.permissions = action.payload.permissions
        state.recomendations = action.payload.recomendations
        state.tags = action.payload.tags
        state.statuses = transformDataStatus(action.payload.statuses)
        state.timezone_code = action.payload.timezone_code
        state.tracking = action.payload.tracking
        state.tos = action.payload.tos
        
    },
    setGlobalLoading: (state, action) =>{
      state.loading =action.payload
    }

    }
  })
  
  export const { setGlobalLandigData, setGlobalLoading } = landingData.actions
  export default landingData.reducer