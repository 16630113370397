import useQuery from 'customHooks/useQuery'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PermissionsOperator from './PermissionsOperator'
import { getKey, setKey } from 'utils/localStorage'

const HomeOperator = () => {

  const [loading, setLoading] = useState(true)


  const dataLanding = useSelector(state => state.landingData.landing)
  const idQuery = useQuery()
  const projectId = useParams().projectId;
  const issueId = idQuery.get('id')
  const token = idQuery.get('token')
  const id = getKey(issueId)

  useEffect(( )=>{
     if(dataLanding) setLoading(false)
     if(!id) setKey(issueId, token)
    }, [dataLanding])
  

  return (
   
      <div className='flex justify-center h-screen w-screen' >
        {loading ? 
      <div className='flex justify-center items-center'>
        <div
          class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
          <span
            class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span>
        </div>
      </div> 
      : 
        <PermissionsOperator/>
      }
    </div>

  )
}

export default HomeOperator