import React, { useEffect, useState } from 'react'
import LayoutCustomer from './LayoutCustomer'
import useQuery from 'customHooks/useQuery'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Input } from 'Components/ui/Input'
import { getKey } from 'utils/localStorage'
import { apiPostFeedbacks } from 'configs/services/ApiFeedbacksService'
import { checkForReload } from 'utils/helpers'

const FeedbacksCustomer = () => {

    const navigate = useNavigate()

    const idQuery = useQuery()
    const projectId = useParams().projectId;
    const issueId = idQuery.get('id')
    const token = idQuery.get('token') || getKey(issueId)

    const dataLanding = useSelector(state => state.landingData.landing)
    const feedbacks = useSelector(state => state.landingData.feedbacks)

    const [selectedFeedbackIssue, setSelectedFeedbackIssue] = useState("");
    const [selectedFeedbackOperator, setSelectedFeedbackOperator] = useState("")
    const [commentFeedbackIssue, setCommentFeedbackIssue] = useState("")
    const [commentFeedbackOperator, setCommentFeedbackOperator] = useState("")
    const [reload, setReload]=useState(false)


    const styleButton = {
        "backgroundColor": dataLanding?.color_primary
    }

    useEffect(()=>{checkForReload()}, [])



    const handlePostFeedbacks = () => {
        const data = {
            "issue_feedback_score": selectedFeedbackIssue,
            "issue_feedback_comment": commentFeedbackIssue,
            "operator_feedback_score": selectedFeedbackOperator,
            "operator_feedback_comment": commentFeedbackOperator
        }
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== "")
          );

        apiPostFeedbacks(issueId, token, filteredData)
        
        setSelectedFeedbackIssue("")
        setSelectedFeedbackOperator("")
        setCommentFeedbackIssue("")
        setCommentFeedbackOperator("")
        navigate(`/${projectId}/7/customer?id=${issueId}`)

    }

    return (
        <LayoutCustomer>
            <div className=' w-[90%] sm:w-[80%] m-auto overflow-hidden '>
                {dataLanding?.image_logo &&
                    <img className='m-auto w-24' src={dataLanding?.image_logo} alt="" />
                }

                <div >
                    <h2 className='text-white text-center font-extrabold mt-6 text-2xl sm:text-xl  '>{feedbacks?.feedbacks_title}</h2>    
                </div>
                <div className='max-h-[400px] h-[400px] sm:max-h-[320px] flex flex-col  w-[90%] m-auto'>
                    <div className='flex flex-col mt-6  '>
                    <p className='text-white text-center text-xl sm:text-lg font-bold my-5  '>{feedbacks?.feedbacks_body}</p>
                   {/*  <span className='m-2 text-white'>Hace click en alguna de las caras para realizar la calificación</span> */}
                        <div className='flex w-full justify-between px-8 md:px-2'>
                            <img className='w-14  h-14 sm:h-12 sm:w-12 cursor-pointer ' src="/img/very-good-icon.png" alt="" onClick={() => setSelectedFeedbackIssue(3)} />
                            <img className='w-14 h-14  sm:h-12 sm:w-12 cursor-pointer ' src="/img/regular-icon.png" alt="" onClick={() => setSelectedFeedbackIssue(2)} />
                            <img className='w-14 h-14 sm:h-12 sm:w-12 cursor-pointer ' src="/img/bad-icon.png" alt="" onClick={() => setSelectedFeedbackIssue(1)} />
                        </div>
                       {/*  <span className='mb-2 mt-4  text-white'>Agrega un comentario a la expriencia </span> */}
                        <div className='mt-5 '>
                            <Input type={"text"} name={"set_last_name"} placeholder={"Ingrese un comentario"}  setter={setCommentFeedbackIssue} valueInput={commentFeedbackIssue} height={8} />
                        </div>
                    </div>

               {/*      <div className='flex flex-col ' >
                        <p className='text-white text-center text-lg sm:text-sm font-bold mb-3  '>Califique la calidad de la llamada</p>
                        <div className='flex w-full justify-between px-8 md:px-2'>
                            <img className='w-14 h-14 sm:h-12 sm:w-12  cursor-pointer ' src="/img/very-good-icon.png" alt="" onClick={() => setSelectedFeedbackOperator(3)} />
                            <img className='w-14 h-14 sm:h-12 sm:w-12 cursor-pointer  ' src="/img/regular-icon.png" alt="" onClick={() => setSelectedFeedbackOperator(2)} />
                            <img className='w-14 h-14 sm:h-12 sm:w-12  cursor-pointer ' src="/img/bad-icon.png" alt="" onClick={() => setSelectedFeedbackOperator(1)} />
                        </div>
                        <div className='mt-2'>
                            <Input type={"text"} name={"set_last_name"} placeholder={"Ingrese un comentario"} setter={setCommentFeedbackOperator} valueInput={commentFeedbackOperator} height={8} />
                        </div>
                    </div> */}
                </div>
                <div className='mt-4 w-[70%] m-auto'>
                    <button onClick={handlePostFeedbacks} className='h-10 w-full rounded-md ' style={styleButton} >
                        <p className='text-white '>Enviar</p>
                    </button>
                </div>
            </div>
        </LayoutCustomer>
    )
}

export default FeedbacksCustomer