import React from "react";
import { useTheme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import HomeOperator from "Views/operator/HomeOperator";
import HomeCustomer from "Views/customer/HomeCustomer";
import FormCustomer from "Views/customer/FormCustomer";
import WaitingRoomCustomer from "Views/customer/WaitingRoomCustomer";
import PermissionsCustomer from "Views/customer/PermissionsCustomer";
import NewWebRtcAdaptor from "Views/pages/NewWebRtcAdaptor";
import WaitingRoomOperator from "Views/operator/WaitingRoomOperator";
import ThanksCustomer from "Views/customer/ThanksCustomer";
import ThanksOperator from "Views/operator/ThanksOperator";
import Page404 from "Views/pages/Page404";
import RecomendationCustomer from "Views/customer/RecomendationCustomer";
import FeedbacksCustomer from "Views/customer/FeedbacksCustomer";
import PermissionCustomerFake from "Views/customer/PermissionCustomerFake";


const withWebRtcAdaptor = (Component) => (props) => (
  <NewWebRtcAdaptor>
    <Component {...props} />
  </NewWebRtcAdaptor>
);

// Envuelve los componentes de las vistas con NewWebRtcAdaptor donde sea necesario
const WaitingRoomOperatorWithAdaptor = withWebRtcAdaptor(WaitingRoomOperator);
const PermissionsCustomerWithAdaptor = withWebRtcAdaptor(PermissionsCustomer);
const WaitingRoomCustomerWithAdaptor = withWebRtcAdaptor(WaitingRoomCustomer);

const isComponent = () => {
  return document.getElementById("root").getAttribute("data-room-name");
}

const  CustomRoutes = (props)=> {
  const theme = useTheme();

  if (isComponent()) {
    return (
      <div className={`grid bg-${theme.palette.background}`}>
        <Routes>
          <Route path="/" element={<Page404/>} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div>
      <Routes>
        <Route path="/" element={<Page404/>} />
        <Route path="/:projectId/operator/" element={<HomeOperator />} />
        <Route path="/:projectId/1/operator/" element={<WaitingRoomOperatorWithAdaptor />} />
        <Route path="/:projectId/4/operator/" element={<ThanksOperator/>} />

        
        <Route path="/:projectId/customer/" element={<HomeCustomer />} />
        <Route path="/:projectId/1/customer" element={<FormCustomer/>} />
        <Route path="/:projectId/2/customer" element={<RecomendationCustomer/>} />
        <Route path="/:projectId/3/customer/" element={<PermissionCustomerFake/>} />
        <Route path="/:projectId/4/customer/" element={<PermissionsCustomerWithAdaptor/>} />
        <Route path="/:projectId/5/customer/" element={<WaitingRoomCustomerWithAdaptor/>} />
        <Route path="/:projectId/6/customer/" element={<FeedbacksCustomer/>} />
        <Route path="/:projectId/7/customer/" element={<ThanksCustomer/>} />
      </Routes>
    </div>
    )
  }
}
export default CustomRoutes;
