import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SvgIcon } from '../../SvgIcon';
/* import { ConferenceContext } from 'pages/AntMedia'; */
import { Tooltip, Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useSelector } from 'react-redux';

const CustomizedBtn = styled(Button)(({ theme }) => ({
  '&.footer-icon-button':{
    height: '100%',
    [theme.breakpoints.down('sm')]:{
      padding:8,
      minWidth: 'unset',
      width: '100%',
      '& > svg': {
        width: 36
      },
    },
  }
}));

function MessageButton({ footer,setMenuOpen,  ...props }) {
  const {t} = useTranslation();
  const conference = useContext(ConferenceContext);

  const dataLanding = useSelector(state => state.landingData.landing)

  const buttonClasses = `bg-[#292D46] rounded-full p-1 flex items-center justify-center ${
    footer ? "w-14 h-14 sm:w-10 sm:h-10": ""
  } shadow-md`;

  const styleButton = {
    "backgroundColor":dataLanding?.color_primary
  }

  return (
    <Badge
      badgeContent={conference?.numberOfUnReadMessages}
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{height: '100%',width:'100%'}}
    >
      <Tooltip title={t('Chat with everyone')} placement="top">
        <button
         style={styleButton}
          onClick={() => {
            if (!conference?.messageDrawerOpen) {
              conference?.toggleSetNumberOfUnreadMessages(0);
            }
            conference?.handleMessageDrawerOpen(!conference?.messageDrawerOpen);
            setMenuOpen && setMenuOpen(false)
          }}
          variant="contained"
          className={buttonClasses}
          color={conference?.messageDrawerOpen ? 'primary' : 'secondary'}
        >
          <SvgIcon size={40} color={conference?.messageDrawerOpen ? 'black' : 'white'} name={'message-off'} />
        </button>
      </Tooltip>
    </Badge>
  );
}

export default MessageButton;
