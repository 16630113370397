import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { SvgIcon } from "../../SvgIcon";
// import { AntmediaContext } from "App";
import { Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
/* import { ConferenceContext } from "pages/AntMedia"; */
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "customHooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { apiPutIssuesStatus } from "configs/services/ApiIssuesStatus";
import { getKey } from "utils/localStorage";
import Modal from 'react-modal';
import { setEndCallMosdalIsOpen } from "stores/statusModal";
import "../../../assets/styles/_messageDialog.css"


function EndCallButton({ footer, ...props }) {


  const conference = useContext(ConferenceContext);
  const dispatch = useDispatch()
  const statuses = useSelector(state => state.landingData.statuses)
  const idQuery = useQuery()
  const issueId = idQuery.get('id')
  const projectId = useParams().projectId;


  const { user } = props
  const navigate = useNavigate()


  const endCall = () => {
       dispatch( setEndCallMosdalIsOpen(true) ) 
  }

  const { t } = useTranslation();
  // const exit = () => {
  //   ahndleLeaveFromRoom();

  // }
  const buttonClasses = `bg-red-500 rounded-full p-1 flex items-center justify-center ${footer ? "w-14 h-14 sm:w-10 sm:h-10" : ''
    }`;
  return (
    <Tooltip title={t('Leave call')} placement="top">
      <button
        onClick={() => endCall()}
        className={buttonClasses}
      >
        <SvgIcon size={28} name="end-call" className="text-white" />
        {footer && (
          <span className="sr-only">{t('Leave call')}</span>
        )}
      </button>
    </Tooltip>
  );
}

export default EndCallButton;
