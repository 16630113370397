import useQuery from 'customHooks/useQuery';
import Loading from 'Views/pages/Loading';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { inIframe, isMobile } from 'utils/helpers';

const LayoutCustomer = ({ children }) => {

  const dataLanding = useSelector(state => state.landingData.landing);
  const loading = useSelector(state => state.landingData.loading);

  const backgroundImage = dataLanding ? `url(${dataLanding.image_background_desktop})` : '';
  const backgroundImageMobile = dataLanding ? `url(${dataLanding.image_background_mobile})` : '';

  const idQuery = useQuery()

  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split('/');
  const routeId = pathSegments[2];
  const isRouteId = routeId === "5";


  /* The .image-container class needs to be applied to a div wrapped around the image you want to apply this filter to */
  const containerStyleDesktop = {
    backgroundImage: backgroundImage,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // Add other CSS properties for your container here
  };
  const containerStyleMobile = {
    backgroundImage: backgroundImageMobile,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    // Add other CSS properties for your container here
  };


  const divWidthClass = isRouteId ? "w-[100%]" : "w-[25%]  right-[10%] ";
  const divheigthClass = isRouteId ? "h-[100%] max-h-[100%] " : "h-[90%] max-h-[550px] top-[10%] ";

  useEffect(() => { }, [dataLanding])


  return (
    <>
      {loading ?
        <><Loading /></>
        :
        <>
          {isMobile() || inIframe() ?
            <div className="h-screen w-screen py-4 overflow-hidden" style={containerStyleMobile}>
              {children}
            </div>
            :
            <div className="h-screen w-screen " style={containerStyleDesktop}>
              <div
                style={containerStyleMobile}
                className={`absolute ${divWidthClass} min-w-[300px] ${divheigthClass}   bg-white rounded-lg py-4 overflow-y-auto`}
              >
                {children}
              </div>
            </div>
          }
        </>

      }
    </>
  );
};

export default LayoutCustomer;
