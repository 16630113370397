import { createSlice } from '@reduxjs/toolkit'

export const usersData = createSlice({
    name: 'users',
    initialState: {
      customer: null,
      operator: null, 
      
    
    },
    
    reducers: {
      setGlobalUsersData: (state, action) => {
        state.customer = action.payload.customer
        state.operator = action.payload.operator
    },
    }
  })
  
  export const { setGlobalUsersData } = usersData.actions
  export default usersData.reducer