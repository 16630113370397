import React, { useContext, useEffect, useRef } from "react";
import { Tooltip, } from "@mui/material";
import MicButton, { CustomizedBtn, roundStyle, } from "Components/Footer/Components/MicButton";
import CameraButton from "Components/Footer/Components/CameraButton";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SettingsDialog } from "Components/Footer/Components/SettingsDialog";
import { SvgIcon } from "Components/SvgIcon";
import { useSnackbar } from "notistack";
import { getUrlParameter } from "@antmedia/webrtc_adaptor";
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import NewVideoCard from "Components/Cards/NewVideoCard";
import NewMeetingRoomOperator from "Views/operator/NewMeetingRoomOperator";
import useQuery from "customHooks/useQuery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParticipantListDrawer from "Components/ParticipantListDrawer";
import MessageDrawer from "Components/MessageDrawer";
import { apiPutIssuesStatus } from "configs/services/ApiIssuesStatus";
import { getKey } from "utils/localStorage";
import EndCallButton from "Components/Footer/Components/EndCallButton";
import InformationDialog from "Components/ui/InformationDialog";
import { setEndCallMosdalIsOpen, setResponseEndCall } from "stores/statusModal";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { apiPostStats } from "configs/services/ApiGeneralService";


function getRoomName() {
    return document.getElementById("root").getAttribute("data-room-name");
}

function getPublishStreamId() {
    const dataRoomName = document.getElementById("root").getAttribute("data-publish-stream-id");
    return (dataRoomName) ? dataRoomName : getUrlParameter("streamId");
}

const WaitingRoomOperator = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const idQuery = useQuery()
    const projectId = useParams().projectId;
    const issueId = idQuery.get('id')
    const token = idQuery.get('token') || getKey(issueId)
    const issueIdServer =`${issueId}_`
    const id = (getRoomName()) ? getRoomName() : issueIdServer;
    const publishStreamId = getPublishStreamId()
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectFocus, setSelectFocus] = React.useState(null);

    const roomName = id;

    const dataLanding = useSelector(state => state.landingData.landing)
    const statuses = useSelector(state => state.landingData.statuses)
    const userCustomer = useSelector(state=> state.usersData.operator)
 
    
    const endCallModalIsOpen = useSelector(state => state.statusModal.modalEndCallIsOpen)

    const conference = useContext(ConferenceContext);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!conference.isPlayOnly && conference.initialized) {
            conference.setLocalVideo(document.getElementById("localVideo"));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conference.initialized]);

    const closeDialogClientEndCall = ()=>{
        setTimeout(()=>{conference.setClienteEndCall(false)}, 3000)
    }

    useEffect(()=>{
        conference.clientEndCall && closeDialogClientEndCall ()
    }, [conference.clientEndCall])



    const closeModal = () => {
        dispatch(setEndCallMosdalIsOpen(false))
    }

    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

   
    const joinRoom = (e) => {
     /*    conference.setStreamName(userCustomer.id) */

        if (conference.localVideo === null && conference.isPlayOnly === false) {
            e.preventDefault();
            enqueueSnackbar(
                {
                    message: t(
                        "You need to allow microphone and camera permissions before joining"
                    ),
                    variant: "info",
                    icon: <SvgIcon size={24} name={"muted-microphone"} color="#fff" />,
                },
                {
                    autoHideDuration: 1500,
                }
            );
            return;
        }
        let streamId;
        if (publishStreamId === null || publishStreamId === undefined) {
            streamId = issueIdServer + "operator" + "_" + userCustomer.id;
            console.log("generatedStreamI:" + streamId);
        } else {
            streamId = publishStreamId;
        }

        conference.joinRoom(roomName, streamId, conference.roomJoinMode);
        conference.setWaitingOrMeetingRoom("meeting");
        apiPutIssuesStatus(issueId, getKey(issueId), statuses.OPERATOR_CONNECT)
    }

    const handleDialogOpen = (focus) => {
        if (false && conference.localVideo === null) {
            enqueueSnackbar(
                {
                    message: t(
                        "You need to allow microphone and camera permissions before changing settings"
                    ),
                    variant: "info",
                    icon: <SvgIcon size={24} name={"muted-microphone"} color="#fff" />,
                },
                {
                    autoHideDuration: 1500,
                }
            );
            return;
        }
        setSelectFocus(focus);
        setDialogOpen(true);
    };
    const handleDialogClose = (value) => {
        setDialogOpen(false);
    };

    const styleButtonSetting = {
        "backgroundColor": dataLanding.color_primary
    }

    const buttonClasses = `bg-[#292D46] rounded-full p-1 flex items-center justify-center w-10 h-10 shadow-md`;
    


         // estadisticas del operador

  const checkSendStata = useRef()
  const sendStats = () => {

    const rtt = ((parseFloat(conference.stats.videoRoundTripTime) + parseFloat(conference.stats.audioRoundTripTime)) / 2).toPrecision(4);
    const data = {
      average_bitrate: conference.stats.averageOutgoingBitrate, 
      max_bitrate: conference.stats.currentOutgoingBitrate,  
      round_trip_time: parseFloat(rtt), 
      video_packets_lost: conference.stats.videoPacketsLost,
      audio_packets_lost: conference.stats.audioPacketsLost,
      video_rtt: conference.stats.videoRoundTripTime,
      audio_rtt: conference.stats.audioRoundTripTime,
      video_jitter: conference.stats.videoJitter,
      audio_jitter: conference.stats.audioJitter,
    };
    apiPostStats(issueId, token, data)
  }


  const sendDataStats = () =>
  (checkSendStata.current = setInterval(
    () => sendStats(),
    15000
  ));


    const endCall = () => {
        conference.handleOperatorEndCall()
        dispatch(setEndCallMosdalIsOpen(false))
        apiPutIssuesStatus(issueId, getKey(issueId), statuses[`OPERATOR_FINISH_CALL`])
        conference.setLeftTheRoom(true)
        navigate(`/${projectId}/4/operator?id=${issueId}`)
        conference.handleLeaveFromRoom()
        clearInterval(checkSendStata.current)
    }

    const refreshWaitingRoom = ()=>{
        conference.setErrorStreamIdInUse(false)
        navigate(`/${projectId}/1/operator?id=${issueId}`)
    }

    const styleButton = {
        "backgroundColor": dataLanding?.color_primary
    }
  
    return (
        <>
            <SettingsDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                selectFocus={selectFocus}
                handleBackgroundReplacement={conference.handleBackgroundReplacement}
                user={"OPERATOR"}
            />
            {conference.waitingOrMeetingRoom === "waiting" ?
                <div className="mx-auto p-4 max-w-xl">
                    <div className="grid grid-cols-1  gap-4 items-center justify-center">
                        {!conference.isPlayOnly && (
                            <div className="md:col-span-2">
                                <div className="relative">
                                    <div className="waiting-room-video relative">
                                        <NewVideoCard id="localVideo" autoPlay muted hidePin={true} width={"w-[100%]"}
                                            height={"h-[100%]"} />
                                    </div>
                                    <div className="absolute bottom-0 left-0 p-2 z-10 flex ">
                                        <div className="flex space-x-2">
                                            <CameraButton rounded />
                                            <MicButton rounded />
                                            <EndCallButton rounded user={"OPERATOR"} />
                                        </div>
                                        <div className="ml-10">
                                            <Tooltip title={t("More options")} placement="top">
                                                <button
                                                    style={styleButtonSetting}
                                                    variant="contained"
                                                    className={buttonClasses}
                                                    onClick={() => handleDialogOpen()}
                                                >
                                                    <SvgIcon size={40} name={"settings"} color={"white"} />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-center text-white mt-2">
                                    {t("You can choose whether to open your camera and microphone before you get into room")}
                                </p>
                            </div>
                        )}
                        <div className={`md:col-span-${conference.isPlayOnly ? '12' : '1'}`}>
                            <div className="flex flex-col items-center justify-center">
                                {/* <p className="text-2xl">{t("What's your name?")}</p> */}
                                <form onSubmit={(e) => joinRoom(e)} className="mt-3 mb-4">
                                    <button
                                        type="submit"
                                        style={styleButtonSetting}
                                        className="w-full mt-4 bg-secondary text-white rounded-lg p-2"
                                        id="room_join_button"
                                    >
                                        {t("I'm ready to join")}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                  {/*   <ParticipantListDrawer />*/}
                    <MessageDrawer /> 
                    <NewMeetingRoomOperator sendDataStats={sendDataStats}/>
                </>
            }
              {
                    conference.errorStreamIdInUse &&
                    <InformationDialog isOpen={conference.errorStreamIdInUse} >
                        <div className="flex flex-col w-80 h-70 p-8 justify-center items-center bg-white absolute top-[25vh] md:top-[10vh] md:left-[37%] rounded-md">
                            <div className=" text-black text-5xl mb-4">
                                <HiOutlineInformationCircle />
                            </div>
                            <h2 className=" text-3xl text-black my-2 font-bold ">{t("attention")}</h2>
                            <h4 className=" text-lg text-gray-600 my-2 text-center">{t("call_in_line")} </h4>
                            <div className="flex flex-row w-[80%] justify-between mt-7">
                            <button className="w-[100%] h-10 rounded-md text-white font-bold" style={styleButton} onClick={refreshWaitingRoom}>{t("acept")}</button>
                        </div>
                            
                        </div>
                    </InformationDialog>
                } 
                {endCallModalIsOpen && <InformationDialog isOpen={endCallModalIsOpen} >
                    <div className="flex flex-col w-80 h-70 p-8 justify-center items-center bg-white absolute top-[25vh] md:top-[10vh] md:left-[37%] rounded-md">
                        {/* <button onClick={closeModal}>close</button> */}
                        <div className=" text-black text-5xl mb-4">
                            <HiOutlineInformationCircle />
                        </div>
                        <h2 className=" text-3xl text-black my-2 font-bold ">{t("confirm")}</h2>
                        <h4 className=" text-lg text-gray-600 my-2 text-center">{t("message_leave_call")}</h4>
                        <div className="flex flex-row w-[80%] justify-between mt-7">
                            <button className="w-20 h-10 rounded-md text-white font-bold" style={styleButton} onClick={endCall}>Si</button>
                            <button className="w-20 h-10 rounded-md text-white font-bold" style={styleButton} onClick={closeModal}>No</button>
                        </div>
                    </div>
                </InformationDialog>}
                {conference.clientEndCall && 
                <InformationDialog isOpen={conference.clientEndCall}  >
                <div className="flex flex-col w-80 h-70 p-8 justify-center items-center bg-white absolute top-[25vh] md:top-[10vh] md:left-[37%] rounded-md">
                    {/* <button onClick={closeModal}>close</button> */}
                    <div className=" text-black text-5xl mb-4">
                        <HiOutlineInformationCircle />
                    </div>
                    <h2 className=" text-3xl text-black my-2 font-bold ">{t("confirm")}</h2>
                    <h4 className=" text-lg text-gray-600 my-2 text-center">{t("message_client_end_call")}</h4>
                {/*     <div className="flex flex-row w-[80%] justify-between mt-7">
                        <button className="w-20 h-10 rounded-md text-white font-bold" style={styleButton} onClick={endCall}>Si</button>
                        <button className="w-20 h-10 rounded-md text-white font-bold" style={styleButton} onClick={closeModal}>No</button>
                    </div> */}
                </div>
            </InformationDialog>}
        </>
    );
}
export default WaitingRoomOperator;
