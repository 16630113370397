import React, { useContext, useEffect } from "react";
import { SvgIcon } from "../../SvgIcon";
/* import { ConferenceContext } from "pages/AntMedia"; */
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useSelector } from "react-redux";
import classNames from "classnames";
import useQuery from "customHooks/useQuery";
import { apiPutIssuesStatus } from "configs/services/ApiIssuesStatus";
import { getKey } from "utils/localStorage";


const  CamButton = (props) => {


  const {user}=props
  const idQuery = useQuery()
  const issueId = idQuery.get('id')

  const { rounded, footer } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const conference = useContext(ConferenceContext);
  const dataLanding = useSelector(state => state.landingData.landing)
  const statuses = useSelector(state => state.landingData.statuses)


  const buttonClasses =classNames(
    `rounded-full p-1 flex items-center justify-center ${footer ? "w-14 h-14 sm:w-10 sm:h-10": ""} shadow-md`
  ) 

  const buttonClassesError = ` bg-red-500 rounded-full p-1 flex items-center justify-center ${
    footer ? "w-14 h-14 sm:w-10 sm:h-10": ""
  } shadow-md`;

  const handleOff = (e) => {
    e.stopPropagation();
    if (conference.isPlayOnly === null) {
      enqueueSnackbar(
        {
          message: t(
            "You need to allow camera and microphone permissions turning off your camera"
          ),
          variant: "info",
          icon: <SvgIcon size={24} name={"muted-microphone"} color="#fff" />,
        },
        {
          autoHideDuration: 1500,
        }
      );
      return;
    }
    if (!conference.isScreenShared) {
      conference?.toggleSetCam({
        eventStreamId: "localVideo",
        isCameraOn: false,
      });
      if (conference.publishStreamId) {
        conference.checkAndTurnOffLocalCamera(conference.publishStreamId);
        conference.handleSendNotificationEvent(
          "CAM_TURNED_OFF",
          conference.publishStreamId
        );
      } else {
        // if local
        conference.checkAndTurnOffLocalCamera("localVideo");
      }
    }
    
    apiPutIssuesStatus(issueId, getKey(issueId), statuses[`${user}_TURNOFF_CAMERA`])
  };
  const handleOn = (e) => {
    e.stopPropagation();
    conference?.toggleSetCam({
      eventStreamId: "localVideo",
      isCameraOn: true,
    });

    if (conference.publishStreamId) {
      conference.checkAndTurnOnLocalCamera(conference.publishStreamId);
      conference.handleSendNotificationEvent(
        "CAM_TURNED_ON",
        conference.publishStreamId
      );
    } else {
      // if local
      conference.checkAndTurnOnLocalCamera("localVideo");
    }
    apiPutIssuesStatus(issueId, getKey(issueId), statuses[`${user}_TURNON_CAMERA`])
  };


  const cam = conference?.cam?.find((m) => m.eventStreamId === "localVideo");

  const styleButton = {
    "backgroundColor":dataLanding?.color_primary
  }


  return (
    <>
      {cam && cam.isCameraOn ? (
        <Tooltip
          title={
            conference.isScreenShared
              ? t("Camera is disabled while screensharing")
              : t("Turn off camera")
          }
          placement="top"
        >
          <button
            className={buttonClasses}
            style={styleButton}
            /* sx={rounded ? roundStyle : {}} */
            disabled={conference.isScreenShared}
            onClick={(e) => handleOff(e)}
          >
            <SvgIcon size={40} name={"camera"} color="white" />
          </button>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            conference.isScreenShared
              ? t("Camera is disabled while screensharing")
              : t("Turn on camera")
          }
          placement="top"
        >
          <button
            className={buttonClassesError}
            disabled={conference.isScreenShared}
            onClick={(e) => handleOn(e)}
          >
            <SvgIcon size={40} name={"camera-off"} color="#fff" />
          </button>
        </Tooltip>
      )}
    </>
  );
}

export default CamButton;
