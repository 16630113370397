import React, { useEffect, useRef, useState } from "react";
import Footer from "Components/Footer/Footer";
import { styled } from "@mui/material/styles";
/* import { ConferenceContext } from "./AntMedia"; */
import NewVideoCard from "Components/Cards/NewVideoCard";
import { ConferenceContext } from "../pages/NewWebRtcAdaptor";
import useQuery from "customHooks/useQuery";
import { getKey } from "utils/localStorage";
import { apiPostStats } from "configs/services/ApiGeneralService";


function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function calculateLayout(
  containerWidth,
  containerHeight,
  videoCount,
  aspectRatio
) {
  let bestLayout = {
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
  };
  // brute-force search layout where video occupy the largest area of the container
  for (let cols = 1; cols <= videoCount; cols++) {
    const rows = Math.ceil(videoCount / cols);
    const hScale = containerWidth / (cols * aspectRatio);
    const vScale = containerHeight / rows;
    let width;
    let height;
    if (hScale <= vScale) {
      width = Math.floor(containerWidth / cols);
      height = Math.floor(width / aspectRatio);
    } else {
      height = Math.floor(containerHeight / rows);
      width = Math.floor(height * aspectRatio);
    }
    const area = width * height;
    if (area > bestLayout.area) {
      bestLayout = {
        area,
        width,
        height,
        rows,
        cols,
      };
    }
  }
  return bestLayout;
}

const NewMeetingRommCustomer = React.memo((props) => {

  const conference = React.useContext(ConferenceContext);

  // estadisticas del cliente
  const stats = conference.stats

  useEffect(() => {
    if (conference && stats) {

      props.sendDataStats()
    /*   props.startFocusCheck() */
    } 
   
  }, [stats])

  const allParticipantsExceptLocal = conference.allParticipants.filter(
    (p) => p.streamId !== conference.publishStreamId
  );
  const filterAndSortOthersTile = (all, showing) => {
    const participantIds = showing.map(({ id }) => id);
    const othersIds = all.filter((p) => !participantIds.includes(p.streamId));
    return othersIds.sort((a, b) => a.streamName.localeCompare(b.streamName));
  };
  useEffect(() => {
    let localVid = document.getElementById("localVideo");
    if (localVid) {
      conference.setLocalVideo(document.getElementById("localVideo"));
    }
  }, [conference.pinnedVideoId]);
  
  function handleGalleryResize(calcDrawer) {
    const gallery = document.getElementById("meeting-gallery");
    if (calcDrawer) {
      if (conference.messageDrawerOpen || conference.participantListDrawerOpen) {
        gallery.classList.add("drawer-open");
      } else {
        gallery.classList.remove("drawer-open");
      }
    }
    const aspectRatio = 16 / 9;
    const screenWidth = gallery.getBoundingClientRect().width;
    const screenHeight = gallery.getBoundingClientRect().height;
    const videoCount = document.querySelectorAll(
      "#meeting-gallery .single-video-container.not-pinned"
    ).length;
    const { width, height, cols } = calculateLayout(
      screenWidth,
      screenHeight,
      videoCount,
      aspectRatio
    );
    let Width = width - 8;
    let Height = height - 8;
    gallery.style.setProperty("--width", `calc(100% / ${cols})`);
    gallery.style.setProperty("--maxwidth", Width + "px");
    gallery.style.setProperty("--height", Height + "px");
    gallery.style.setProperty("--cols", cols + "");
  }

  React.useEffect(() => {
    handleGalleryResize(false);
  }, [conference.participants, conference.pinnedVideoId]);

  React.useEffect(() => {
    handleGalleryResize(true);
  }, [conference.messageDrawerOpen, conference.participantListDrawerOpen]);

  React.useEffect(() => {
    const debouncedHandleResize = debounce(handleGalleryResize, 500);
    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const getUnpinnedParticipants = () => {
    const array = [
      conference.pinnedVideoId !== "localVideo" && { id: "localVideo" },
      ...conference.participants.filter((v) => v.id !== conference.pinnedVideoId),
    ];
    const filtered = array.filter(Boolean);
    return filtered;
  };

  const OthersTile = (maxGroup, othersArray = []) => {
    let others = [];
    if (othersArray?.length > 0) {
      others = othersArray;
    } else {
      others = filterAndSortOthersTile(
        allParticipantsExceptLocal,
        conference.participants
      );
    }
  };

  const returnUnpinnedGallery = () => {
    //pinned tile
    let unpinnedParticipants = getUnpinnedParticipants();

    const showAsOthersLimitPinned = 5;
    const showAsOthersSliceIndexPinned = showAsOthersLimitPinned - 2;

    const slicePinnedTiles =
      unpinnedParticipants.length + 1 > showAsOthersLimitPinned;

    let slicedParticipants = [];
    if (slicePinnedTiles) {
      slicedParticipants = unpinnedParticipants.slice(
        0,
        showAsOthersSliceIndexPinned
      );
      unpinnedParticipants = unpinnedParticipants.slice(
        showAsOthersSliceIndexPinned
      );
    } else {
      slicedParticipants = unpinnedParticipants;
    }




    return slicedParticipants.length > 0 ? (
      <div className="mr-[1%]">
        {slicedParticipants.map(({ id, videoLabel, track, name }, index) => {
          if (id !== "localVideo") {
            return (
              <div className="unpinned z-20 absolute inset-0 overflow-hidden" key={index}>
                <div className="single-video-container">
                  <div className="absolute right-0 top-0 h-[90%] ">

                    <NewVideoCard
                      onHandlePin={() => {
                        conference.pinVideo(id, videoLabel);
                      }}
                      id={id}
                      track={track}
                      autoPlay
                      name={name}
                      width={"md:w-[75%] lg:w-[100%] w-[100%]"}
                      height={"md:h-[75%] lg:h-[100%] h-[90%]"}
                      user={"CUSTOMER"} 
                    />
                  </div>
                </div>
              </div>
            );
          } else if (conference.isPlayOnly === false) {
            return (
              <div className="unpinned z-20 absolute inset-0 overflow-hidden" >
                <div className="single-video-container " key={index}>
                  <div className="absolute right-0 top-0 h-[90%] ">
                    <NewVideoCard
                      onHandlePin={() => {
                        conference.pinVideo("localVideo", "localVideo");
                      }}
                      id="localVideo"
                      autoPlay
                      name="You"
                      muted
                      width={"md:w-[75%] lg:w-[100%] w-[100%]"}
                      height={"md:h-[75%] lg:h-[100%] h-[90%]"}
                      user={"CUSTOMER"} 
                    />

                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
        {sliceTiles ? (
          <div className="unpinned">
            <div className="single-video-container  others-tile-wrapper">
              {OthersTile(2)}
            </div>
          </div>
        ) : (
          slicePinnedTiles && (
            <div className="unpinned">
              <div className="single-video-container  others-tile-wrapper">
                {OthersTile(2, unpinnedParticipants)}
              </div>
            </div>
          )
        )}
      </div>
    ) : (
      <p className="text-green-50 mt-3 text-sm">
        No other participants.
      </p>
    );
  };

  //main tile other limit set, max count
  const showAsOthersLimit = conference.globals.maxVideoTrackCount + 1; // the total video cards i want to see on screen including my local video card and excluding the others tile. if this is set to 2, user will see 3 people and 1 "others card" totaling to 4 cards and 2x2 grid.
  //with 2 active video participants + 1 me + 1 card
  const sliceTiles = allParticipantsExceptLocal.length + 1 > showAsOthersLimit; //plus 1 is me

  const pinLayout = conference.pinnedVideoId !== null ? true : false;
  // const testPart = [{ name: 'a' }, { name: 'a' }];

  const pinnedVideo = pinLayout && conference.participants.find((v) => v.id === conference.pinnedVideoId)

  const [isUser, setIsUser] = useState(false)

  useEffect(() => {
    if (conference.participants[0] && !conference.pinnedVideoId) {
      conference.pinVideo(conference.participants[0].id, conference.participants[0].videoLabel);
     /*  setIsUser(true) */
    }
  }, [conference.participants,conference.pinnedVideoId ])

/*   useEffect(() => {
    if (!conference.pinnedVideoId) setIsUser(false)
  }, [conference.pinnedVideoId]) */

  useEffect(() => {
    return () => {
      setIsUser(false)
    }
  }, [])


  useEffect(()=>{
    if(conference.errorStreamIdInUse) conference.setWaitingOrMeetingRoom("waiting")
  }, [conference.errorStreamIdInUse]) 


  const landscape = () =>
  window.addEventListener("orientationchange", function rotate() {
    if (window.orientation === 90 || window.orientation === -90) {
      conference.setRotate(true);
    } else {
      conference.setRotate(false);
    }
  });

  useEffect(() => {
    landscape();
  }, [conference.rotate]);


  //modal 

  return (
    <>
      {conference.audioTracks.map((audio, index) => (
        <>
          <NewVideoCard
            key={index}
            onHandlePin={() => {
            }}
            id={audio.streamId}
            track={audio.track}
            autoPlay
            name={""}
            style={{ display: "none" }}
            user={"CUSTOMER"} 
          />
        </>
      ))}
      <div id="meeting-gallery" style={{ height: "calc(100vh - 100px)" }}>
        {!pinLayout && ( // if not pinned layout show me first as a regular video
          <>
            {conference.isPlayOnly === false ?
              <div
                className="flex justify-center items-center single-video-container not-pinned"
                style={{
                  width: "var(--width)",
                  height: "var(--height)",
                  maxWidth: "var(--maxwidth)",
                }}
              >
                <NewVideoCard
                  onHandlePin={() => {
                    conference.pinVideo("localVideo", "localVideo");
                  }}
                  id="localVideo"
                  autoPlay
                  name="You"
                  muted
                  hidePin={conference.participants.length === 0}
                  user={"CUSTOMER"} 
                />
              </div> : null}
            {conference.participants
              .filter((p) => p.videoLabel !== p.id)
              .map(({ id, videoLabel, track, name }, index) => (
                <>
                  <div
                    className="single-video-container not-pinned"
                    key={index}
                    style={{
                      width: "var(--width)",
                      height: "var(--height)",
                      maxWidth: "var(--maxwidth)",
                    }}
                  >
                    <NewVideoCard
                      onHandlePin={() => {
                        conference.pinVideo(id, videoLabel);
                      }}
                      id={id}
                      track={track}
                      autoPlay
                      name={name}
                      user={"CUSTOMER"} 
                    />
                  </div>
                </>
              ))}
          </>
        )}
        {pinLayout && (
          <>
            {conference.pinnedVideoId === "localVideo" ? (
              // pinned myself
              // ${participants.length === 0 ? ' no-participants ' : ''}
              <div className="single-video-container pinned keep-ratio absolute flex justify-center items-end">
                <NewVideoCard
                  onHandlePin={() => {
                    conference.pinVideo("localVideo", "localVideo");
                  }}
                  id="localVideo"
                  autoPlay
                  width={"md:w-[70%] lg:w-[60%] w-[100%]"}
                  height={"md:h-[65%] lg:h-[80%] h-[65%]"}
                  position={"relative"}
                  bottom={"bottom-[10%]"}
                  name="You"
                  muted
                  pinned
                  user={"CUSTOMER"} 
                />
              </div>
            ) : (
              //pinned participant (cuando ingresa en la llamada este el video central)
              pinnedVideo && (
                <div className="single-video-container pinned keep-ratio absolute flex justify-center items-end">

                  <NewVideoCard
                    id={pinnedVideo?.id}
                    track={
                      pinnedVideo?.track
                    }
                    width={`md:w-[70%] lg:w-[60%] ${conference.rotate ? "w-[70%]" : "w-[100%]" } `}
                    height={`md:h-[65%] lg:h-[80%] ${conference.rotate ? "h-[100%]" : "h-[65%]"  }`}
                    position={"relative"}
                    bottom={`${conference.rotate ? "top-[10%]" : "bottom-[10%]"  }`}
                    autoPlay
                    name={
                      pinnedVideo?.name
                    }
                    pinned
                    onHandlePin={() => {
                      conference.pinVideo(
                        pinnedVideo.id,
                        pinnedVideo.videoLabel
                      );
                    }}
                    user={"CUSTOMER"} 
                  />
                </div>
              )
            )}
            <div id="unpinned-gallery">{returnUnpinnedGallery()}</div>
          </>
        )}
      </div>

      <div className="flex w-full justify-center">
        <Footer {...props} user={"CUSTOMER"} />
      </div>
 
    </>

  )
});

export default NewMeetingRommCustomer;
