import { configureStore } from '@reduxjs/toolkit'
import landingData from './stores/landingData'
import usersData from 'stores/usersData'
import statusModal from 'stores/statusModal'

export default configureStore({
    reducer: {
        landingData, 
        usersData, 
        statusModal
    }
})