import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useQuery from 'customHooks/useQuery';
import { useSelector } from 'react-redux';
import { apiPutIssuesStatus } from 'configs/services/ApiIssuesStatus';
import { getKey } from 'utils/localStorage';
import { useTranslation } from "react-i18next";

const PermissionsOperator = () => {
  const { t } = useTranslation();

    const [permissionStatus, setPermissionStatus] = useState(null);
    const dataLanding = useSelector(state => state.landingData.landing)
    const statuses = useSelector(state => state.landingData.statuses)
  
    const navigate = useNavigate()
  
    const idQuery = useQuery()
    const projectId = useParams().projectId;
    const issueId = idQuery.get('id')
    const token = idQuery.get('token')
  
    const checkPermissions = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {
          setPermissionStatus("accepted");
        })
        .catch((error) => {
          if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
            setPermissionStatus("denied"); 
          }
        });
    };;
  
    useEffect(() => {
      setTimeout(() => {
        checkPermissions()
      }, 3000)
    }, [])
  
    useEffect(() => {
      if (permissionStatus === "accepted") {
        apiPutIssuesStatus(issueId, getKey(issueId), statuses.OPERATOR_ACCEPT_PERMISSIONS)
        setTimeout(() => {
          navigate(`/${projectId}/1/operator?id=${issueId}&token=${token}`)
        }, 3000)
      } if (permissionStatus === "denied") {
        apiPutIssuesStatus(issueId, getKey(issueId), statuses.OPERATOR_REJECT_PERMISSIONS)
      }
  
    }, [permissionStatus])
  
    return (
      <>
        {dataLanding && 
        
        <div className='flex flex-col justify-center items-center w-[90%] m-auto'>
        <img className='m-auto w-24' src={dataLanding?.image_favicon} alt="" />
          <div className='flex flex-col justify-center items-center ' >
            <img src="/img/waiting-room.png" alt="..." className='w-20 mb-5 mt-5' />
            <div className="flex flex-col justify-center items-center mt-5" >
            {(permissionStatus === "denied") ?
              <>
                <p className='text-white text-sm m-2 text-center'>{t("message_device_not_permissions")}</p>
                <p className='text-white text-xs m-2 text-center'>{t("message_permissions_not_acept")} </p>
              </> :
              <>
                <p className='text-white text-sm m-2 text-center'>{t("message_waiting_permissions_operator")} </p>
                <p className='text-white text-xs m-2 text-center'>{t("message_recomdations_permits")} </p>
              </>
            }
  
            </div>
            <img src="/img/permits.gif" alt="..." height="150" className="mt-5" />
          </div>
  
        </div>
        }
      </>
    )
  }
  

export default PermissionsOperator