import ApiService from "./ApiService";

export async function apiPostMessages (guid, token, message) {
    return ApiService.fetchData({
        url: `/issues/${guid}/chat`,
        headers: {
            'Authorization': `Bearer ${token}` 
        },
        method: 'post',
        data:{"message": message}
        //params
    })
}
