import React, { useEffect, useState } from 'react'
import LayoutCustomer from './LayoutCustomer'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'Components/ui/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { setGlobalLandigData } from 'stores/landingData'
import useQuery from 'customHooks/useQuery'
import Loading from 'Views/pages/Loading'
import { useTranslation } from "react-i18next";

const FormCustomer = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation();

  const idQuery = useQuery()
  const projectId = useParams().projectId;
  const issueId = idQuery.get('id')

  const [checkedTyc, setCheckedTyc] = useState(false)

  const dataLanding = useSelector(state => state.landingData.landing)
  const dataContent = useSelector(state => state.landingData.content)
  const dataForm = useSelector(state => state.landingData.form)
  const loading = useSelector(state => state.landingData.loading)
  const termsAndConditions = useSelector(state => state.landingData.tos)
  const recomendations= useSelector(state => state.landingData.recomendations)


  const styleButton = {
    "backgroundColor": dataLanding?.color_primary
  }

  const handleClick = () => {
    if(recomendations?.allow_recomendations) navigate(`/${projectId}/2/customer?id=${issueId}`)
    else navigate(`/${projectId}/3/customer?id=${issueId}`)
  }

  useEffect(() => { }, [dataForm])

  const handleChecked = (e) => {
    setCheckedTyc(!checkedTyc)
  }


  return (
    <>
      {
        loading ? <Loading />
          :
          <LayoutCustomer>

            <div className=' w-[80%] m-auto overflow-hidden '>

              {dataLanding?.image_logo &&
                <img className='m-auto w-24' src={dataLanding?.image_logo} alt="" />
              }
              {
                dataContent?.greetings_title &&
                <div className='mt-5'>
                  <h2 className='text-white font-bold text-lg px-4 text-center'>{dataContent?.greetings_title}</h2>
                </div>
              }
              {
                dataContent?.greetings_body &&
                <div className='mt-5'>
                  <h4 className='text-white font-bold text-sm px-4 text-center'>{dataContent?.greetings_body}</h4>
                </div>
              }
              <div className='flex flex-col justify-center '>

                <div className='flex'>

                  <form action="">
                    {dataForm?.set_first_name && <Input type={"text"} name={"set_first_name"} placeholder={t("name")} />}
                    {dataForm?.set_last_name && <Input type={"text"} name={"set_last_name"} placeholder={t("lastname")}  />}
                    {dataForm?.set_email && <Input type={"email"} name={"set_first_name"} placeholder={t("email")}  />}
                    {dataForm?.set_phone && <Input type={"text"} name={"set_phone"} placeholder={t("phone")}  />}
                    {dataForm?.set_personal_id && <Input type={"text"} name={"set_personal_id"} placeholder={t("personal_id")}  />}
                  </form>
                </div>

                {termsAndConditions?.allow_tos && 
                  <div className='flex flex-row justify-center items-center gap-2 mt-4'>
                    <input type="checkbox" onChange={e => { handleChecked(e) }} checked={checkedTyc} className="checkbox border border-white" />
                    <a  href={termsAndConditions?.tos_url} target="_blank" rel="noreferrer noopener" className='text-white text-sm'>{t("acept_terms_and_conditions")}</a>
                  </div>
                }

                <div className='mt-4'>
                  <button onClick={handleClick} className='h-10 w-full rounded-md ' style={styleButton} >
                    <p className='text-white '>{t("continue")}</p>
                  </button>
                </div>
              </div>
              <span className=' flex justify-center m-auto text-white text-[10px] mt-5'>Version 1.0</span>
            </div>
          </LayoutCustomer>
      }
    </>
  )
}

export default FormCustomer