import React, { useState } from "react";
import MicButton from "./Components/MicButton";
import CameraButton from "./Components/CameraButton";
import OptionButton from "./Components/OptionButton";
import ShareScreenButton from "./Components/ShareScreenButton";
import MessageButton from "./Components/MessageButton";
import ParticipantListButton from "./Components/ParticipantListButton";
import EndCallButton from "./Components/EndCallButton";
import { useParams } from "react-router-dom";
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useSelector } from "react-redux";
import { isMobile } from "utils/helpers";
import { FiMoreVertical } from "react-icons/fi";
import "./Components/styles.css"


function getRoomName() {
  return document.getElementById("root").getAttribute("data-room-name");
}
const Footer = (props) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const id = (getRoomName()) ? getRoomName() : useParams().id;
  const conference = React.useContext(ConferenceContext);

  const dataLanding = useSelector(state => state.landingData.landing)
  const { user } = props

  // Define un estado para el menú desplegable
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Función para manejar la apertura/cierre del menú desplegable
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  return (

    <div className="flex items-center justify-center mt-4 bottom-1 sm:bottom-5 absolute z-2000  mx-auto m">
      {!isMobile() &&
        <div className=" fixed bottom-[3%] left-[10%] sm:flex items-center ">
          <img src={dataLanding.image_icon} alt="logo" className="w-12 mr-1" />
          <p className="text-white text-base">{id}</p>
          {/*  <InfoButton /> */}
        </div>

      }
     <div className={`flex justify-center space-x-2 absolute ${conference.rotate ? "bottom-[-75px]" : "bottom-1"} `}>
        {
          /* !isMobile() && */
          <div /* className=" sm:block" */>
            <OptionButton footer user={user} />
          </div>
        }

        {!isMobile() && conference.isPlayOnly === false && (
          <div /* className=" sm:block" */>
            <ShareScreenButton footer user={user} />
          </div>
        )}
  {/*       {!isMobile() &&
          <div >
            <MessageButton footer user={user} />
          </div>
        } */}

        {conference.isPlayOnly === false && (
          <div /* className=" sm:block" */>
            <MicButton footer user={user} />
          </div>
        )}
        {conference.isPlayOnly === false && (
          <div /* className=" sm:block" */>
            <CameraButton {...props} footer user={user} />
          </div>
        )}


        {
          !isMobile &&
          <div /* className=" sm:block" */>
            <ParticipantListButton footer user={user} />
          </div>
        }

        <div /*  className=" sm:block" */>
          <EndCallButton footer user={user} />
        </div>

      <div className="ml-5 ">

        {/* Botón de menú desplegable */}
       {/*  {isMobile() && (
          <div
            className={`cursor-pointer absolute bottom-3 ${isMenuOpen ? "menu-open" : ""}`}
            onClick={toggleMenu}
          >
            <FiMoreVertical size={24} color="white" />
          </div>
        )}
 */}
        {/* Menú desplegable */}
      {/*   {isMobile() && isMenuOpen && (
          <div className={`flex flex-col space-y-2 menu-dropdown absolute bottom-8 z-2000`}>
            <div>
              <OptionButton footer user={user} />
            </div>

 
            <div>
              <MessageButton footer user={user} setMenuOpen={setMenuOpen} />
            </div>
          </div>
        )} */}
      </div>
      </div>

      {/*  <div className=" sm:flex">
        <TimeZone />
      </div> */}
    </div>
  );
}

export default Footer;
