import React, { useEffect, useState } from 'react'
import LayoutCustomer from './LayoutCustomer'
import { useNavigate, useParams } from 'react-router-dom';
import useQuery from 'customHooks/useQuery';
import { useSelector } from 'react-redux';
import { getKey } from 'utils/localStorage';
import { apiPutIssuesStatus } from 'configs/services/ApiIssuesStatus';
import { useTranslation } from "react-i18next";

const PermissionsCustomer = () => {

  const { t } = useTranslation();

  const [permissionStatus, setPermissionStatus] = useState(null);
  const dataLanding = useSelector(state => state.landingData.landing)
  const statuses = useSelector(state => state.landingData.statuses)

  const navigate = useNavigate()

  const idQuery = useQuery()
  const projectId = useParams().projectId;
  const issueId = idQuery.get('id')

  const checkPermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {
        setPermissionStatus("accepted");
      })
      .catch((error) => {
        if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
          setPermissionStatus("denied");
        }
      });
  };;

/*   useEffect(() => {
    setTimeout(() => {
      checkPermissions()
    }, 5000)
  }, []) */

  useEffect(() => {
    setTimeout(()=>{
      checkPermissions()
    }, 4000)
      
  }, [])

/*     useEffect(() => {
      if (permissionStatus === "accepted") {
        apiPutIssuesStatus(issueId, getKey(issueId), statuses.CUSTOMER_ACCEPT_PERMISSIONS)
        setTimeout(() => {
          navigate(`/${projectId}/5/customer?id=${issueId}`)
        }, 2000)
      } if (permissionStatus === "denied") {
        apiPutIssuesStatus(issueId, getKey(issueId), statuses.CUSTOMER_REJECT_PERMISSIONS)
      }
  
    }, [permissionStatus]) */
    useEffect(() => {
      if (permissionStatus === "accepted") {
        statuses &&  apiPutIssuesStatus(issueId, getKey(issueId), statuses?.CUSTOMER_ACCEPT_PERMISSIONS)
          navigate(`/${projectId}/5/customer?id=${issueId}`)
      } if (permissionStatus === "denied") {
        statuses &&  apiPutIssuesStatus(issueId, getKey(issueId), statuses?.CUSTOMER_REJECT_PERMISSIONS)
      }
  
    }, [permissionStatus, statuses])


  return (
    <LayoutCustomer>
      {dataLanding && 
      
      <div className='flex flex-col justify-center items-center w-[90%] m-auto'>
      <img className='m-auto w-24' src={dataLanding?.image_favicon} alt="" />
        <div className='flex flex-col justify-center items-center ' >
          <img src="/img/waiting-room.png" alt="..." className='w-20 mb-5 mt-5' />
          <div className="flex flex-col justify-center items-center mt-5" >
            {(permissionStatus === "denied") ?
              <>
                <p className='text-white text-sm m-2 text-center'>{t("message_device_not_permissions")}a</p>
                <p className='text-white text-xs m-2 text-center'>{t("message_permissions_not_acept")} </p>
              </> :
              <>
                <p className='text-white text-sm m-2 text-center'>{t("message_waiting_permissions")} </p>
                <p className='text-white text-xs m-2 text-center'>{t("message_recomdations_permits")} </p>
              </>
            }

          </div>
          <img src="/img/permits.gif" alt="..." height="150" className="mt-5" />
        </div>

      </div>
      }
    </LayoutCustomer>
  )
}

export default PermissionsCustomer