import ApiService from "./ApiService";

export async function apiGetProjects (guid, token) {
    return ApiService.fetchData({
        url: `/project/${guid}`,
        headers: {
            'Authorization': `Bearer ${token}` 
        },
        method: 'get',
        //params
    })
}

export async function apiGetUsers (guid, token) {
    return ApiService.fetchData({
        url: `/issues/${guid}`,
        headers: {
            'Authorization': `Bearer ${token}` 
        },
        method: 'get',
        //params
    })
}


export async function apiPostStats (guid, token, data) {
    return ApiService.fetchData({
        url: `streaming/log/${guid}`,
        headers: {
            'Authorization': `Bearer ${token}` 
        },
        method: 'post',
        data
        //params
    })
}
