import classNames from "classnames";
import React, { useState } from "react";
import { isMobile, isIOS} from "utils/helpers";

export const Input = ({ name, type, placeholder, setter, valueInput, height }) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    setter(e.target.value);
  };

  const handleBlur = () => {
    if (valueInput === "") {
      setter("");
    }
  };

  const heightInput = height ? height : 12


  const styles = /* isIOS() ? ` h-${heightInput} w-full opacity-60 p-2 text-white placeholder-white` : */ ` h-${heightInput} w-full  opacity-60 p-2 text-black placeholder-black` 

  return (
    <div className="flex flex-col my-1 p-1">
   
    <div className="border-2 border-white rounded-lg">
      <input
        className={styles}
        type={type}
        name={name}
        placeholder={placeholder}
        value={valueInput}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  </div>
  )
};
