import ApiService from "./ApiService";

export async function apiPutIssuesStatus (guid, token, issueStatusId) {
    return ApiService.fetchData({
        url: `/issues/${guid}`,
        headers: {
            'Authorization': `Bearer ${token}` 
        },
        method: 'put',
        data:{"issue_status_id": issueStatusId},
     
    })
}