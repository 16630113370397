import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";
import HeadManager from "HeadManager";


const root = ReactDOM.createRoot(document.getElementById("root"));
let appName = undefined
let roomName = document.getElementById("root").getAttribute("data-room-name");

/* if (process.env.NODE_ENV !== 'development' && !roomName) {
  appName = "/" + window.location.pathname.split("/")[1];
  console.log = function () { };
}
 */

Sentry.init({
  dsn: "https://64c0066e41ba48c89fa7c23f12972f0b@o1305921.ingest.sentry.io/6547865",
  integrations: [
    new Sentry.BrowserTracing({
       tracePropagationTargets: ["https://webapp-staging.vivet.app",],
    }),
    new Sentry.Replay(),
 ],
 // Performance Monitoring
 tracesSampleRate: 1.0, // Capture 100% of the transactions
 // Session Replay
 replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
 replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <BrowserRouter /* basename={appName} */>
    <Provider store={store}>
      <HeadManager/>
      <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
