import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SvgIcon } from '../../SvgIcon';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
/* import { ConferenceContext } from 'pages/AntMedia'; */
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useSelector } from 'react-redux';

const CustomizedBtn = styled(Button)(({ theme }) => ({
  '&.footer-icon-button':{
    height: '100%',
    [theme.breakpoints.down('sm')]:{
      padding: 8,
      minWidth: 'unset',
      width: '100%',
      '& > svg': {
        width: 36
      },
    },
  }
}));

function ParticipantListButton({ footer, ...props }) {
    const conference = React.useContext(ConferenceContext);
    const {t} = useTranslation();
    const dataLanding = useSelector(state => state.landingData.landing)
    

    const buttonClasses = `bg-[#292D46] rounded-full p-1 flex items-center justify-center ${
      footer ? 'w-10 h-10' : ''
    } shadow-md`;
  
    const styleButton = {
      "backgroundColor":dataLanding?.color_primary
    }
  

    return (
            <Tooltip title={t('Participant List')} placement="top">
                <button
                /*     onClick={() => {
                        conference?.handleParticipantListOpen(!conference?.participantListDrawerOpen);
                    }} */
                    className={buttonClasses}
                    style={styleButton}
                >
                    <SvgIcon size={32} color={conference?.participantListDrawerOpen ? 'black' : 'white'} name={'participants'} />
                    {conference.isPlayOnly === false ? conference.allParticipants.length + 1 : conference.allParticipants.length}
                </button>
            </Tooltip>
        );
}

export default ParticipantListButton;
