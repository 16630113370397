import React, { useEffect } from 'react'
import LayoutCustomer from './LayoutCustomer'
import { useSelector } from 'react-redux'
import useQuery from 'customHooks/useQuery'
import { useNavigate, useParams } from 'react-router-dom'
import { checkForReload, isMobile } from 'utils/helpers'
import { useTranslation } from "react-i18next";

const RecomendationCustomer = () => {

  const { t } = useTranslation();
  
  const idQuery = useQuery()
  const projectId = useParams().projectId;
  const issueId = idQuery.get('id')
  
  const navigate = useNavigate()

  const recomendations= useSelector(state => state.landingData.recomendations)
  const dataLanding = useSelector(state => state.landingData.landing)


  const styleButton = {
    "backgroundColor": dataLanding?.color_primary
  }
  const handleClick = () => {
    navigate(`/${projectId}/3/customer?id=${issueId}`)
  }
 
  useEffect(()=>{checkForReload()}, [])
  
  useEffect(()=>{
    if(recomendations){
      if(isMobile() && !recomendations?.recomendation_mobile) navigate(`/${projectId}/3/customer?id=${issueId}`)
      if(!isMobile() && !recomendations?.recomendation_desktop) navigate(`/${projectId}/3/customer?id=${issueId}`)
    }

  }, [recomendations])
 
  return (
    <LayoutCustomer>
      <div className=' w-[80%] m-auto overflow-hidden '>
      {dataLanding?.image_logo &&
                <img className='m-auto w-24' src={dataLanding?.image_logo} alt="" />
              }
              {isMobile() && recomendations?.recomendation_mobile &&
              <div className='mt-8 text-center'>
              <p className='text-white' >{recomendations?.recomendation_mobile}</p>
              </div>}
              {!isMobile() && recomendations?.recomendation_desktop &&
              <div  className='mt-8 text-center' >
              <p className='text-white' >{recomendations?.recomendation_desktop}</p>
              </div>}
                <div className='mt-4'>
                  <button onClick={handleClick} className='h-10 w-[80%] rounded-md absolute bottom-3 ' style={styleButton} >
                    <p className='text-white '>{t("acept")}</p>
                  </button>
                </div>
      </div>
    </LayoutCustomer>
  )
}

export default RecomendationCustomer