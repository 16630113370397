import React, { useEffect, useState } from 'react'
import LayoutCustomer from './LayoutCustomer'
import { useNavigate, useParams } from 'react-router-dom';
import useQuery from 'customHooks/useQuery';
import { useSelector } from 'react-redux';
import { getKey } from 'utils/localStorage';
import { apiPutIssuesStatus } from 'configs/services/ApiIssuesStatus';
import { useTranslation } from "react-i18next";

const PermissionCustomerFake = () => {
    const { t } = useTranslation();

    const dataLanding = useSelector(state => state.landingData.landing)

    const navigate = useNavigate()

    const idQuery = useQuery()
    const projectId = useParams().projectId;
    const issueId = idQuery.get('id')

      useEffect(() => {
          setTimeout(() => {
            navigate(`/${projectId}/4/customer?id=${issueId}`)
          }, 2000)
      }, [])
  
    return (
      <LayoutCustomer>
        {dataLanding && 
        
        <div className='flex flex-col justify-center items-center w-[90%] m-auto'>
        <img className='m-auto w-24' src={dataLanding?.image_favicon} alt="" />
          <div className='flex flex-col justify-center items-center ' >
            <img src="/img/waiting-room.png" alt="..." className='w-20 mb-5 mt-5' />
            <div className="flex flex-col justify-center items-center mt-5" >
                  <p className='text-white text-sm m-2 text-center'>{t("message_waiting_permissions")} </p>
                  <p className='text-white text-xs m-2 text-center'>{t("message_recomdations_permits")} </p>
            </div>
            <img src="/img/permits.gif" alt="..." height="150" className="mt-5" />
          </div>
  
        </div>
        }
      </LayoutCustomer>
    )
}

export default PermissionCustomerFake