import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { SvgIcon } from "../../SvgIcon";
/* import { ConferenceContext } from "pages/AntMedia"; */
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useSelector } from "react-redux";
import useQuery from "customHooks/useQuery";
import { apiPutIssuesStatus } from "configs/services/ApiIssuesStatus";
import { getKey } from "utils/localStorage";

const CustomizedBtn = styled(Button)(({ theme }) => ({
  "&.footer-icon-button": {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      minWidth: "unset",
      width: "100%",
    },
    "& > svg": {
      width: 36,
    },
  },
}));


const  ShareScreenButton = ({ footer, ...props }) => {

  const {user}=props
  const { t } = useTranslation();
  const conference = useContext(ConferenceContext);

  const idQuery = useQuery()
  const issueId = idQuery.get('id')
  
  const dataLanding = useSelector(state => state.landingData.landing)
  const statuses = useSelector(state => state.landingData.statuses)
  
  const buttonClasses = `bg-[#292D46] rounded-full p-1 flex items-center justify-center ${
    footer ? "w-14 h-14 sm:w-10 sm:h-10" : ''
  } shadow-md`;

  const styleButton = {
    "backgroundColor":dataLanding?.color_primary
  }

  return (
    <Tooltip
      title={conference.isScreenShared ? t("You are presenting") : t("Present now")}
      placement="top"
    >
    <button
       onClick={() => {
          if (conference.isScreenShared) {
            conference.handleStopScreenShare();
            
            
          } else {
            conference.handleStartScreenShare();
            apiPutIssuesStatus(issueId, getKey(issueId), statuses[`${user}_SHARE_SCREEN`])
            // send other that you are sharing screen.
          }
        }}
      className={buttonClasses}
      style={styleButton}
    >
        <SvgIcon
          color={conference.isScreenShared ? "black" : "white"}
          size={40}
          name={"share-screen-off"}
        />
      </button>
    </Tooltip>
  );
}

export default ShareScreenButton;
