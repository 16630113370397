import React, { useEffect } from 'react'
import LayoutCustomer from './LayoutCustomer'
import { useSelector } from 'react-redux'
import { TbWorldWww, TbMailShare} from "react-icons/tb";
import { URL_REGEXP } from 'utils/helpers';

const ThanksCustomer = () => {

  const dataContent = useSelector(state => state.landingData.content)
  const dataLanding = useSelector(state => state.landingData.landing)


  useEffect(()=>{
    if (dataContent?.thanks_redirection && URL_REGEXP.test(dataContent?.thanks_redirection))
			setTimeout(() => window.location.assign(dataContent?.thanks_redirection), 10000);
  }, [dataContent])

  return (
    <LayoutCustomer>
      <div className=' w-[80%] m-auto overflow-hidden '>
        <img className='m-auto w-24' src={dataLanding?.image_favicon} alt="" />
        <div className='h-[400px] flex flex-col justify-between '>
          {dataContent?.thanks_title &&
            <div className='mt-5 text-center ' >
              <h2 className='text-white font-bold text-4xl px-4 '>{dataContent?.thanks_title}</h2>
            </div>
          }

          {dataContent?.thanks_body &&
            <div className='mt-5 text-center justify-center' >
              <h4 className='text-white font-bold text-base px-4 '>{dataContent?.thanks_body}</h4>
            </div>
          }
          <div className='flex justify-center items-center gap-4'>
          {
            dataContent?.thanks_redirection &&
            <div className='mt-5 text-center text-white text-4xl  ' >
              <a  href={dataContent?.thanks_redirection} target="_blank" rel="noreferrer noopener"><TbWorldWww  /></a>
            </div>
          }
                  {
            dataContent?.thanks_email &&
            <div className='mt-5 text-center text-white text-4xl  ' >
              <a  href={`mailto:${dataContent?.thanks_email}`} target="_blank" rel="noreferrer noopener"><TbMailShare  /></a>

            </div>
          }
          </div>
        </div>
      </div>
    </LayoutCustomer>
  )
}

export default ThanksCustomer