import React from 'react'
import { useSelector } from 'react-redux'
import useQuery from 'customHooks/useQuery'

const ThanksOperator = () => {

  const dataLanding = useSelector(state => state.landingData.landing)
  const idQuery = useQuery()
  const issueId = idQuery.get('id')

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(issueId);
    } catch (error) {
      console.error('Error al copiar texto: ', error);
    }
  };
  
  return (
    <div className='flex h-screen w-screen flex-col' >
       {dataLanding && 
    
  <div className='flex flex-col justify-center items-center'>
    <img className='m-auto mt-14 w-24' src={dataLanding?.image_favicon} alt="" />
      <div className='mt-14'>
        <p className='text-white text-3xl'>La llamada ha finalizado. </p>
      </div>
      <div className='mt-14 flex flex-col md:flex-row'>
        <p className='text-white text-xl mr-2' >Identificador de la llamada:</p> <span className=' text-white text-xl cursor-pointer ' onClick={copyToClipboard}> {issueId}  </span> 
      </div>
  </div>

   
    }
      </div>
  )
}

export default ThanksOperator