
import  { useEffect } from 'react';
import { useLocation } from 'react-router-dom';  // Asegúrate de tener react-router-dom instalado

const HeadManager = () => {
  const location = useLocation();  // Esto nos da acceso a la URL actual

  useEffect(() => {
    const url = location.pathname.toLowerCase();  // Obtienes la parte de la ruta de la URL
    const favicon = document.querySelector("link[rel='icon']");
    const appleIcon = document.querySelector("link[rel='apple-touch-icon']");
    const ogSiteName = document.querySelector("meta[property='og:site_name']");


 if (url.includes("36d9a729-e9b4-4dcd-bc34-90ef98744f37")) {
      favicon.href = "/img/icon_bago.png";
      appleIcon.href = "/img/apple_icon_bago.png";
      ogSiteName.content = "Bago Insurance";
      document.title = "Bago";
    
    } else {
      favicon.href = "/img/favicon.png";
      appleIcon.href = "%PUBLIC_URL%/icon.png";
      ogSiteName.content = "Vivet";
      document.title = "Vivet";
  
    }
  }, [location]);

  return null;  // Este componente no necesita renderizar nada
};

export default HeadManager;
