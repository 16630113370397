import "./App.css";
/* eslint-disable eqeqeq */
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./styles/theme";
import React, { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import AntSnackBar from "Components/AntSnackBar";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import translationEN from "./configs/i18n/en.json";
import translationTR from "./configs/i18n/tr.json";
import translationES from "./configs/i18n/es.json";
import CustomRoutes from "CustomRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { apiGetProjects, apiGetUsers } from "configs/services/ApiGeneralService";
import { setGlobalLandigData, setGlobalLoading } from "stores/landingData";
import useQuery from "customHooks/useQuery";
import { getKey } from "utils/localStorage";
import { setGlobalUsersData } from "stores/usersData";

const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationTR,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    keySeperator: false,
    resources,
  });

const availableLangs = Object.keys(resources);
if (!availableLangs.includes(i18n.language)) {

  const maybeLang = i18n.language.slice(0, 2);
  if (availableLangs.includes(maybeLang)) {
    localStorage.setItem("i18nextLng", maybeLang);
    i18n.changeLanguage(maybeLang);
  } else {
    // Falling back to english.
    localStorage.setItem("i18nextLng", "en");
    i18n.changeLanguage("en");
  }

}

function getWindowLocation() {
  document.getElementById("locationHref").value = window.location.href;
}

function copyWindowLocation() {
  var copyText = document.getElementById("locationHref");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy");
}

window.getWindowLocation = getWindowLocation;
window.copyWindowLocation = copyWindowLocation;

const App = () => {

   
  const dispatch = useDispatch()
  const navigate = useNavigate()
 
  const [loading, setLoading] = useState(true)
  const idQuery = useQuery()
  const issueId = idQuery.get('id')
  const token = idQuery.get('token') || getKey(issueId)

  const handleFullScreen = (e) => {
    if (e.target?.id === "meeting-gallery") {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("dblclick", handleFullScreen);

    // cleanup this component
    return () => {
      window.removeEventListener("dblclick", handleFullScreen);
    };
  }, []);


  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split('/');
  const projectId = pathSegments[1];

  const userLanguage = useSelector(state => state.landingData.language_code)
  const colorDialog = useSelector(state => state.landingData.landing?.color_primary)
  const colorSecondary = useSelector(state => state.landingData.landing?.color_secondary)
  
  i18n.changeLanguage(userLanguage);

  useEffect(() => {

    try {
      if (projectId) {
          apiGetProjects(projectId, token).then((res) => {
            if(res.data){
              const { data } = res
              dispatch(setGlobalLandigData(data.data))
              setLoading(false)
              dispatch(setGlobalLoading(false))
            }
           
          }).catch((e)=>{
          navigate("/")
          console.log(e)})

           apiGetUsers(issueId, token).then((res)=>{
            if(res.data){
              dispatch(setGlobalUsersData(res.data.data))
            } 
          }).catch((e)=>{
            navigate("/")
            console.log(e)}) 
      }
      
    } catch (error) {
      navigate("/")
      console.log(error)
    }
  }, [])
  // "#d2c8f1", "#323135", "#000", "#1b1b1b", "white"
  return (
    <ThemeProvider theme={theme(false, false,false,false,false, colorDialog, colorSecondary)}>

      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        maxSnack={3}
        content={(key, notificationData) => (
          <AntSnackBar id={key} notificationData={notificationData} />
        )}
      >
          {  <CustomRoutes /> }

      </SnackbarProvider>

    </ThemeProvider>
  );
}

export default App;
