import axios from 'axios'

export const baseUrl = process.env.REACT_APP_API_HOST

const BaseService = axios.create({
    timeout: 60000*5,
    baseURL: `${baseUrl}/api/v1`,
})


export default BaseService