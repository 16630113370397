import React from 'react'

const Page404 = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen w-screen bg-white px-12 sm:px-3'>
      <p className=' text-5xl md:text-6xl  font-bold text-red-700 my-5'>Error 404</p>
      <p className='text-base font-semibold text-grey-700 text-center '>No pudimos encontrar la página solicitada. Por favor, revise la URL y reintente.</p>

    </div>
  )
}

export default Page404