import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { SvgIcon } from "../../SvgIcon";
/* import { ConferenceContext } from "pages/AntMedia"; */
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ConferenceContext } from "Views/pages/NewWebRtcAdaptor";
import { useSelector } from "react-redux";
import { getKey } from "utils/localStorage";
import { apiPutIssuesStatus } from "configs/services/ApiIssuesStatus";
import useQuery from "customHooks/useQuery";

export const roundStyle = {
  width: { xs: 36, md: 46 },
  height: { xs: 36, md: 46 },
  minWidth: "unset",
  maxWidth: { xs: 36, md: 46 },
  maxHeight: { xs: 36, md: 46 },
  borderRadius: "50%",
  padding: "4px",
};

export const CustomizedBtn = styled(Button)(({ theme }) => ({
  "&.footer-icon-button": {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      minWidth: "unset",
      width: "100%",
    },
    "& > svg": {
      width: 36,
    },
  },
}));

const  MicButton = (props) => {

 
  const idQuery = useQuery()
  const issueId = idQuery.get('id')
  const { rounded, footer, user } = props;
  const conference = useContext(ConferenceContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const dataLanding = useSelector(state => state.landingData.landing)
  const statuses = useSelector(state => state.landingData.statuses)


  const buttonClasses = `bg-[#292D46] rounded-full p-1 flex items-center justify-center ${
    footer ? "w-14 h-14 sm:w-10 sm:h-10" : ""
  } shadow-md`;

  const buttonClassesError = ` bg-red-500 rounded-full p-1 flex items-center justify-center ${
    footer ? "w-14 h-14 sm:w-10 sm:h-10" : ""
  } shadow-md`;


  const handleMute = (e) => {
    e.stopPropagation();
    if (conference.localVideo === null) {
      enqueueSnackbar(
        {
          message: t(
            "You need to allow camera and microphone permissions before muting yourself"
          ),
          variant: "info",
          icon: <SvgIcon size={24} name={"muted-microphone"} color="#fff" />,
        },
        {
          autoHideDuration: 1500,
        }
      );
      return;
    }
    enqueueSnackbar(
      {
        message: t("Microphone off"),
        variant: "info",
        icon: <SvgIcon size={24} name={"muted-microphone"} color="#fff" />,
      },
      {
        autoHideDuration: 1500,
      }
    );

    conference?.toggleSetMic({
      eventStreamId: "localVideo",
      isMicMuted: true,
    });
    conference.muteLocalMic();
    if (conference.publishStreamId) {
      conference.handleSendNotificationEvent(
        "MIC_MUTED",
        conference.publishStreamId
      );
      conference.updateAudioLevel(0);
    }

    apiPutIssuesStatus(issueId, getKey(issueId), statuses[`${user}_MUTE_MIC`])
  };

  const handleUnmute = (e) => {
    e.stopPropagation();
    enqueueSnackbar(
      {
        message: t("Microphone on"),
        variant: "info",
        icon: <SvgIcon size={24} name={"microphone"} color="#fff" />,
      },
      {
        autoHideDuration: 1500,
      }
    );

    conference?.toggleSetMic({
      eventStreamId: "localVideo",
      isMicMuted: false,
    });
    conference.unmuteLocalMic();
    if (conference.publishStreamId) {
      conference.handleSendNotificationEvent(
        "MIC_UNMUTED",
        conference.publishStreamId
      );
    }
    apiPutIssuesStatus(issueId, getKey(issueId), statuses[`${user}_UNMUTE_MIC`])
  };

  const mic = conference?.mic?.find((m) => m.eventStreamId === "localVideo");

  const styleButton = {
    "backgroundColor":dataLanding?.color_primary
  }


  return (
    <>
      {mic && mic.isMicMuted ? (
        <Tooltip title={t("Turn on microphone")} placement="top">
          <button
            className={buttonClassesError}
            onClick={(e) => {
              handleUnmute(e);
            }}
          >
            <SvgIcon size={40} name={"muted-microphone"} color="#fff" />
          </button>
        </Tooltip>
      ) : (
        <Tooltip title={t("Turn off microphone")} placement="top">
          <button
            className={buttonClasses}
            style={styleButton}
            onClick={(e) => {
              handleMute(e);
            }}
          >
            <SvgIcon size={40} name={"microphone"} color="white" />
          </button>
        </Tooltip>
      )}
    </>
  );
}

export default MicButton;
