import useQuery from 'customHooks/useQuery'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getKey, setKey } from 'utils/localStorage'
import Loading from 'Views/pages/Loading'


const HomeCustomer = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const idQuery = useQuery()
  const projectId = useParams().projectId;
  const issueId = idQuery.get('id')
  const token = idQuery.get('token')
  const dataForm = useSelector(state => state.landingData.form)
  const termsAndConditions = useSelector(state => state.landingData.tos)
  const recomendations = useSelector(state => state.landingData.recomendations)
  const id = getKey(issueId)

  const checkForm = () => {
    if (dataForm?.set_email || dataForm?.set_first_name || dataForm?.set_last_name || dataForm?.set_personal_id || dataForm?.set_phone || termsAndConditions?.allow_tos) return true
  }

  const checkRecomendation = () => {
    if (recomendations?.allow_recomendations) return true
  }

  useEffect(() => {
    if (dataForm) {
      if (checkForm()) {
        navigate(`/${projectId}/1/customer?id=${issueId}`)
      }
      else if (!checkForm() && checkRecomendation ()) {
         navigate(`/${projectId}/2/customer?id=${issueId}`)
         }
      else { navigate(`/${projectId}/3/customer?id=${issueId}`) }
    }
    if (!id) setKey(issueId, token)
    setLoading(false)
  }, [dataForm])


  if (loading) {
    return (
      <Loading />
    )

  }

  /*  if (!loading) {
      return(
      <div className='flex justify-center h-screen w-screen' >
        <WaitingRoomOperator />
      </div>
      )
    }  */
}

export default HomeCustomer