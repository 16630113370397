import { createSlice } from '@reduxjs/toolkit'

export const statusModal = createSlice({
    name: 'statusModal',
    initialState: {
      modalEndCallIsOpen: false,
   
    },
    
    reducers: {
      setEndCallMosdalIsOpen: (state, action) => {
        state.modalEndCallIsOpen = action.payload
    },

    }
  })
  
  export const { setEndCallMosdalIsOpen} = statusModal.actions
  export default statusModal.reducer